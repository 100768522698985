import React from 'react'
import ictus from 'assets/images/ictus.svg'
import emporio from 'assets/images/emporio.svg'
import vilaReal from 'assets/images/vilareal.svg'
import useScrollVisibility from 'hooks/useScrollVisibility'

const data = [
  // {
  //   name: 'Empório Maziero',
  //   image: emporio,
  // },
  // {
  //   name: 'Ictus Soluções em Energia',
  //   image: ictus,
  // },
  // {
  //   name: 'Vila Real Odontologia',
  //   image: vilaReal,
  // },
]

function PartnerLogos() {
  const [isVisible, sectionRef] = useScrollVisibility('clientes')

  return (
    <section ref={sectionRef} id='clientes' className='flex min-h-96 flex-col items-center justify-center py-8'>
      <div className='flex w-11/12 flex-col items-center justify-center gap-4 md:w-10/12'>
        <header
          className={`mb-2 flex flex-col items-center justify-center gap-4 text-center md:mb-4 ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}
        >
          <h2 className='text-4xl font-semibold uppercase text-white md:text-6xl'>Eles Confiam e comprovam</h2>
          <p className='text-lg font-medium text-green md:w-2/3 md:text-2xl'>
            Conheça nossos clientes, eles confiaram em nosso método exclusivo para alavancar seus negócios. Fale com a
            nossa equipe: <br /> <span className='text-white'>seja a próxima história de sucesso!</span>
          </p>
        </header>

        <div className='w-full overflow-x-auto'>
          <ul className='flex items-center justify-center gap-8'>
            {data.map((partner, index) => (
              <li key={index} className={`w-44 md:w-56 ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}>
                <picture>
                  <img
                    src={partner.image}
                    alt={partner.name}
                    loading='lazy'
                    className='h-24 w-full cursor-pointer grayscale transition-all duration-300 hover:scale-105 md:h-32'
                  />
                </picture>
              </li>
            ))}
          </ul>
          {/* <p className='text-lg font-medium text-green md:text-2xl'>
            Seja a próxima história de sucesso
          </p> */}
          <button></button>
        </div>
      </div>
    </section>
  )
}

export default PartnerLogos
