import React, { Link } from 'react-router-dom'
import useScrollVisibility from 'hooks/useScrollVisibility'

function AboutAcademiaDorac() {
  const [isVisible, sectionRef] = useScrollVisibility('academia-dorac')

  return (
    <section
      ref={sectionRef}
      id='academia-dorac'
      className='flex min-h-96 flex-col items-center justify-center bg-white bg-opacity-85'
    >
      <div className='my-8 flex w-11/12 flex-col items-center justify-center gap-6 text-center md:w-10/12'>
        <header className={`${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}>
          <h2 className='text-4xl font-bold uppercase text-blue md:text-6xl'>Educação</h2>
        </header>

        <p className={`text-lg md:w-11/12 md:text-2xl ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}>
          Na Academia Dorac, sabemos que a educação é fundamental para impulsionar o progresso humano. Em uma
          sociedade altamente conectada, capacitamos nossos alunos e organizações a atingirem seu máximo potencial por meio da nossa plataforma de ensino.
        </p>

        <button className={`flex items-center justify-center ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}>
          <a
            href='https://samantha-dorabiato.kpages.online/nova-pagina-0f18db55-e474-4929-b728-ca9d44d907f0'
            target='_blank'
            className='rounded-full bg-[#3F2657] px-10 py-3 text-2xl font-medium uppercase text-white hover:opacity-75'
          >
            Nossos Cursos
          </a>
        </button>
      </div>
    </section>
  )
}

export default AboutAcademiaDorac
