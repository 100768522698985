import React, { useState, useEffect } from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp'
import Banner from './components/Banner'
import About from './components/About'
import OurServices from './components/OurServices'
import PartnerLogos from './components/PartnerLogos'
import DoracAcademy from './components/DoracAcademy'
import ContactForm from './components/ContactForm'

function Home() {
  useEffect(() => {
    document.title = 'Dorac Brasil - Marketing & Educação'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main
      style={{
        backgroundImage: `linear-gradient(180deg, rgb(111, 21, 89, .8), rgba(111, 21, 89, .8), rgba(44, 50, 98, .8), rgb(111, 21, 89, .8))`,
      }}
    >
      <Header />
      <Banner />
      <OurServices />
      <About />
      <PartnerLogos />
      <DoracAcademy />
      <ContactForm/>
      <Footer />
      <Whatsapp />
    </main>
  )
}

export default Home
